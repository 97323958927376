@import 'variables';

.btn{
  margin: 0.2em;
  font-family: 'Roboto Condensed', 'Noto Sans', sans-serif;
  &.btn-primary {
    background-color: $caramel;
    border: $primary-green;
  }
  &:hover {
    background-color: $chestnut;
  }
  &:focus,
  &:active {
    background-color: $chestnut !important;
  }
}

#profileBtnFadeIn {
  opacity: 0;
  animation: fadein 1s;
  animation-fill-mode: forwards;
  animation-delay: 2.2s;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.contact-btns {
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .btn {
    height: fit-content;
  }
}